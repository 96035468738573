import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';

import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    Content,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../components/InnerPage';

const ArchivTemplate = ({ data, pageContext }) => {
    const posts = data.allMarkdownRemark.edges;
    const { currentPage, numPages } = pageContext;
    const isFirst = currentPage === 1;
    const isLast = currentPage === numPages;
    const prevPage =
        currentPage - 1 === 1
            ? '/archiv/'
            : '/archiv/seite/' + (currentPage - 1).toString();
    const nextPage = '/archiv/seite/' + (currentPage + 1).toString();
    return (
        <InnerPage
            pageType="blog-page"
            title="Liste aller erschienenen Artikel"
            description="Liste aller erschienenen Artikel"
        >
            <IntroHolder title="Liste aller erschienenen Artikel" />
            <TwoColumns>
                <Content>
                    {posts.map(({ node }, id) => {
                        // Null value possible
                        const article_image =
                            node.frontmatter.hasOwnProperty(
                                'thumbnail_image'
                            ) && node.frontmatter.thumbnail_image !== null
                                ? node.frontmatter.thumbnail_image
                                      .childImageSharp.fluid
                                : node.frontmatter.feature_image &&
                                  node.frontmatter.feature_image.childImageSharp
                                      .fluid;
                        return (
                            <article className="post-preview" key={id}>
                                {article_image && (
                                    <div className="img-holder">
                                        <Img fluid={article_image} />
                                    </div>
                                )}
                                <div className="text-holder">
                                    <h2 className="text-blue-900">
                                        <Link
                                            to={`/artikel${node.fields.slug}`}
                                        >
                                            {node.frontmatter.title}
                                        </Link>
                                    </h2>
                                    <div className="meta-info">
                                        {node.frontmatter.date}
                                    </div>
                                    <p>{node.excerpt}</p>
                                </div>
                            </article>
                        );
                    })}
                    <nav className="navigation pagination" role="navigation">
                        <div className="nav-links">
                            {!isFirst && (
                                <Link
                                    to={prevPage}
                                    className="prev page-numbers"
                                    rel="prev"
                                >
                                    Neuere Beiträge
                                </Link>
                            )}
                            {!isLast && (
                                <Link
                                    to={nextPage}
                                    className="next page-numbers"
                                    rel="next"
                                >
                                    Ältere Beiträge
                                </Link>
                            )}
                        </div>
                    </nav>
                </Content>
                <Sidebar />
            </TwoColumns>
        </InnerPage>
    );
};

export default ArchivTemplate;

export const archivQuery = graphql`
    query archivQuery($skip: Int!, $limit: Int!) {
        allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    frontmatter {
                        title
                        date(formatString: "DD. MMMM YYYY", locale: "de")
                        feature_image {
                            childImageSharp {
                                fluid(maxWidth: 300, quality: 80) {
                                    ...GatsbyImageSharpFluid_noBase64
                                }
                            }
                        }
                        thumbnail_image {
                            childImageSharp {
                                fluid(maxWidth: 300, quality: 80) {
                                    ...GatsbyImageSharpFluid_noBase64
                                }
                            }
                        }
                    }
                    excerpt(pruneLength: 70, format: PLAIN)
                    fields {
                        slug
                    }
                }
            }
        }
    }
`;
